import { Layout } from "@react-admin/ra-enterprise";
import { MenuItemCategoryResources } from "@react-admin/ra-navigation"
import { CustomAppBar } from "./CustomAppBar";

const MyMenu = () => <MenuItemCategoryResources hasDashboard={true} />

const MainLayout = (props: any) => (
  <>
    <Layout {...props} appBar={CustomAppBar} menu={MyMenu} />
  </>
);

export default MainLayout;

import { Create, Edit } from "react-admin";
import { OrganizacionCreateEditBaseForm } from "./OrganizacionCreateEditBaseForm";


const CreateOrganizacion = () => {
    return <Create>
        <OrganizacionCreateEditBaseForm isEdit={false} />
    </Create>
};

const EditOrganizacion = () => {
    return <Edit queryOptions={{ meta: { 'refresh': true } }}>
        <OrganizacionCreateEditBaseForm isEdit={true} />
    </Edit>
};

export {
    CreateOrganizacion,
    EditOrganizacion,
}
import {
  List,
  TextField as TextFieldRa,
  Datagrid,
  TextInput,
} from "react-admin";

import { WithPermissions } from "../../lib/permissions";
import { EditableDatagrid, RowForm } from "@react-admin/ra-editable-datagrid";
import { PermissionsData } from "../../api-provider";
import {
  SelectComunidad,
} from "../Direcciones/SelectPoblacionInput";
import { ComunidadFilter } from "../Direcciones/DireccionesFilters";

const provinciasFilters = [
  <TextInput label="Buscar" source="q" alwaysOn />,
  <ComunidadFilter source="comunidad_id" />,
];

export const ListaProvincias = () => (
  <WithPermissions>
    {({ isEditor }: PermissionsData) => (
      <List
        hasCreate={isEditor}
        exporter={isEditor ? undefined : false}
        empty={false}
        filters={provinciasFilters}
        perPage={25}
      >
        {isEditor && (
          <EditableDatagrid
            mutationMode="undoable"
            editForm={<ProvinciaForm />}
            createForm={<ProvinciaForm />}
            size="small"
          >
            <TextFieldRa source="id" />
            <TextFieldRa source="provincia" fullWidth />
            <TextFieldRa
              source="comunidad.comunidad"
              fullWidth
              label="Comunidad"
            />
            <TextFieldRa source="comunidad.pais.pais" fullWidth label="País" />
          </EditableDatagrid>
        )}
        {!isEditor && (
          <Datagrid rowClick={false} size="small">
            <TextFieldRa source="id" />
            <TextFieldRa source="provincia" fullWidth />
            <TextFieldRa
              source="comunidad.comunidad"
              fullWidth
              label="Comunidad"
            />
            <TextFieldRa source="comunidad.pais.pais" fullWidth label="País" />
          </Datagrid>
        )}
      </List>
    )}
  </WithPermissions>
);

const ProvinciaForm = () => (
  <RowForm>
    <TextFieldRa source="id" />
    <TextInput source="provincia" isRequired fullWidth />
    <SelectComunidad isRequired setValue={() => {}} />
    <></>
  </RowForm>
);

export interface Pais {
  id?: number;
  pais: string;
}

export interface Comunidad {
  id?: number;
  comunidad: string;
  pais_id: number;
  pais: Pais;
}

export interface Provincia {
  id?: number;
  provincia: string;
  comunidad_id: number;
  comunidad: Comunidad;
}

export interface Municipio {
  id?: number;
  municipio: string;
  provincia_id: number;
  provincia: Provincia;
}

export interface Direccion {
  id?: number;
  tipo_de_via: string;
  nombre: string;
  numero: string;
  piso: string;
  escalera: string;
  codigo_postal: string;
  municipio_id: number;
  municipio: Municipio;
}
export enum EtiquetaTipos {
  Tipologia = "TIPOLOGIA",
  Subcategoria = "SUBCATEGORIA",
  Categoria = "CATEGORIA",
}

export interface Tipologia {
  id?: number;
  nombre: string;
  tipo: EtiquetaTipos;
  padre?: Tipologia;
  padre_id?: number;
}

export interface Organizacion {
  id?: number;
  nombre: string;
  tipologia: Tipologia;
  direccion: Direccion;
  telefono?: string;
  otro_telefono?: string;
  observaciones?: string;
  email?: string;
}

export interface Cargo {
  id?: number;
  nombre: string;
}

export interface Contacto {
  id?: number;
  nombre: string;
  apellidos: string;
  telefono: string;
  otro_telefono?: string;
  email: string;
  organizacion: Organizacion;
  organizacion_id: number;
  direccion?: Direccion;
  direccion_id?: number;
  cargo: Cargo;
  cargo_id: number;
}

export const representacionDireccion = (d: Direccion) =>
  `${d?.tipo_de_via ?? ``} ${d?.nombre ?? ``} ${d?.nombre ? `,` : ``} ${
    d?.numero ?? ``
  } ${d?.piso ?? ``} ${d?.escalera ?? ``} ${d?.codigo_postal ?? ``} ${
    d?.municipio ? d.municipio.municipio : `-`
  } (${d?.municipio.provincia ? d.municipio.provincia?.provincia : ``})`;

export const representacionMunicipio = (m: Municipio) =>
  `${m?.municipio}, ${m.provincia.provincia} (${m.provincia.comunidad.comunidad}) ${m.provincia.comunidad.pais.pais}`;

export const representacionOrganizacion = (o: Organizacion) =>
  `${o?.nombre} ${o.tipologia ? `(` + o.tipologia.nombre + `)` : ``}`;

export const representacionEtiqueta = (e: Tipologia): string => {
  if (!e.padre) {
    return `${e?.nombre}`;
  }
  return `${representacionEtiqueta(e.padre)} > ${e.nombre}`;
};

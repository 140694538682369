import React from "react";
import {
  useGetList,
  SelectArrayInput,
  useListFilterContext,
} from "react-admin";
import { EtiquetaTipos } from "../../lib/tipos";
import { get } from "lodash";

export const EtiquetaFilter = ({
  label,
  tipo,
  source,
}: {
  label: string;
  tipo: string;
  source: string;
}) => {
  const { filterValues, setFilters } = useListFilterContext();

  
  const initialValues = React.useMemo(
    () => {
      let values : {
        tipo: string;
        id?: number;
        padre_id?: number;
      } = {
        // [source]: filterValues[source],
        tipo
      };

      switch(tipo) {
        case EtiquetaTipos.Categoria:
          // Empty no hay dependencia
          break;
        case EtiquetaTipos.Subcategoria:
          values.padre_id = get(filterValues, 'categoria_id', undefined)
          break;
        case EtiquetaTipos.Tipologia:
          values.padre_id = get(filterValues, 'subcategoria_id', undefined)
          break;
      }
      return values;   
  },
    [filterValues, tipo]
  );

  const { data, isLoading } =
    useGetList("etiquetas", {
      filter: initialValues,
      pagination: { page: 1, perPage: 10000 },
      sort: {
        field: 'nombre',
        order: 'ASC'
      }
    });

  const onSearchChange = (event) => {
    if (event.target) {
      setFilters({ ...filterValues, [source]: event.target.value }, null);
    } else {
      const { [source]: _, ...filters } = filterValues;
      setFilters(filters, null);
    }
  };

  return (
    <SelectArrayInput
      options={{
        label: label,
      }}
      source={source}
      choices={data ? data : []}
      optionText="nombre"
      optionValue="id"
      isLoading={isLoading}
      onChange={onSearchChange}
      fullWidth
      
    />
  );
};

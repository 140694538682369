import React from "react";
import styled from "@emotion/styled";
import { Box, FormControlLabel, Grid, Switch, Typography } from "@mui/material";
import { get } from "lodash";

export const BoxSeparator = () => <Box pt="1em" />;

export const GridColumnSeparator = ({ cols }: { cols?: number }) => (
  <Grid item xs={cols !== undefined ? cols : 1}>&nbsp;</Grid>
);

export const GridRowSeparator = () => <Grid item xs={12}>&nbsp;</Grid>;

export const BoxCard = styled("div")(({ theme }) => ({
  border: "1px solid",
  borderColor: get(theme, 'palette.mode') === "dark" ? "#444d58" : "#ced7e0",
  borderRadius: "4px",
  paddingLeft: 10,
  paddingRight: 10,
}));

export const BoxCardWithTitle = ({
  title,
  children,
}: {
  title: string;
  children: any;
}) => (
  <BoxCard>
    <Grid item xs={12}>
      <Typography variant="body1" gutterBottom>
        {title}
      </Typography>
    </Grid>
    {children}
  </BoxCard>
);

export const OptionalSwitch = ({
  label,
  children,
  defaultState = false,
}: {
  label: string;
  defaultState?: boolean;
  children: any;
}) => {
  const [visible, setVisible] = React.useState(defaultState);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setVisible(event.target.checked);
  };

  return (
    <>
      <FormControlLabel
        label={label}
        control={
          <Switch
            checked={visible}
            onChange={handleChange}
          />
        }
      />

      {visible && { ...children }}
    </>
  );
};

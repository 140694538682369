import { Grid, Skeleton } from "@mui/material";
import { useRecordContext } from "react-admin";
import {
  Organizacion,
  representacionDireccion,
} from "../../lib/tipos";

export const DireccionShowInlineForm = () => {
  const record: Organizacion = useRecordContext();
  
  if (!record) {
    return <Skeleton variant="rectangular" width="100%" height={200} />;
  }

  return (
    <Grid container item xs>
      <Grid item xs={2}>
        <strong>Dirección completa:</strong>
      </Grid>
      <Grid item xs>
        {representacionDireccion(record.direccion)}
      </Grid>
    </Grid>
  );
};

import assert from "assert";
import { AxiosInstance } from "axios";
import { get, isString, merge } from "lodash";
import { stringify } from "querystring";
import {
  DataProvider,
  GetListParams,
  GetOneParams,
  GetManyParams,
  GetManyReferenceParams,
  UpdateParams,
  UpdateManyParams,
  CreateParams,
  DeleteParams,
  DeleteManyParams,
  GetListResult,
  RaRecord,
} from "react-admin";
import { getTokenData, processValidationErrorsMessages } from "./helpers";

const defaultPerPage = 25;

export class AxiosProvider {
  axiosClient: AxiosInstance;

  constructor(httpClient: AxiosInstance) {
    this.axiosClient = httpClient;
  }

  prepareOptions(basicOptions = {}) {
    const token = getTokenData();
    if (token) {
      return merge(
        {
          headers: {
            authorization: `Bearer ${token}`,
          },
        },
        basicOptions
      );
    }
    return basicOptions;
  }

  getList<T extends RaRecord>(
    resource: string,
    params: GetListParams
  ): Promise<GetListResult<T>> {
    const { page, perPage } = params.pagination || { page: 1, perPage: defaultPerPage };
    const { field, order } = params.sort || {
      field: undefined,
      order: undefined,
    };
    const rangeStart = (page - 1) * perPage;
    const rangeEnd = page * perPage - 1;

    const query = {
      sort: JSON.stringify([field, order]),
      pagination: JSON.stringify({ page: page, perPage: perPage }),
      range: JSON.stringify([rangeStart, rangeEnd]),
      filter: JSON.stringify(params.filter),
      meta: JSON.stringify(params.meta), // Nos permite enviar metadata como que es una exportacion, etc.
    };

    const url = `/${resource}?${stringify(query)}`;
    const options = this.prepareOptions();
    return this.axiosClient
      .get(url, options)
      .then(({ data }: { data: any }) => {
        // En caso de exportacion
        if (params.meta?.exporter === true && isString(data?.data.download_url)) {
          setTimeout(function () {
            window.open(data.data.download_url, "_blank");
          }, 100);
          return {
            data: [],
            total: 0
          }
        }

        // console.log(data);
        assert(
          get(data, "data") !== undefined ||
          get(data, "meta.total") !== undefined,
          "Formato invalido en la respuesta"
        );

        return {
          data: data.data,
          total: data.meta.total,
        };
      })
      .catch(processValidationErrorsMessages);
  }

  getOne(resource: string, params: GetOneParams) {
    return this.axiosClient
      .get(`/${resource}/${params.id}`, this.prepareOptions())
      .then(({ data }: { data: any }) => ({
        data: data.data,
      }))
      .catch(processValidationErrorsMessages);
  }

  getMany(resource: string, params: GetManyParams) {
    const query = {
      filter: JSON.stringify({ id: params.ids }),
    };
    const url = `/${resource}?${stringify(query)}`;
    return this.axiosClient
      .get(url, this.prepareOptions())
      .then(({ data }) => ({ data: data.data }))
      .catch(processValidationErrorsMessages);
  }

  getManyReference(resource: string, params: GetManyReferenceParams) {
    const { page, perPage } = params.pagination || { page: 1, perPage: defaultPerPage };
    const { field, order } = params.sort;

    const rangeStart = (page - 1) * perPage;
    const rangeEnd = page * perPage - 1;

    const query = {
      sort: JSON.stringify([field, order]),
      pagination: JSON.stringify({ page: page, perPage: perPage }),
      range: JSON.stringify([rangeStart, rangeEnd]),
      filter: JSON.stringify({
        ...params?.filter,
        [params?.target]: params?.id,
      }),
    };
    const url = `/${resource}?${stringify(query)}`;
    const options = this.prepareOptions();

    return this.axiosClient
      .get(url, options)
      .then(({ data }) => {
        return {
          data: data?.data,
          total: data?.meta?.total,
        };
      })
      .catch(processValidationErrorsMessages);
  }

  update(resource: string, params: UpdateParams) {
    return this.axiosClient
      .put(`/${resource}/${params?.id}`, params.data, this.prepareOptions())
      .then(({ data }) => ({ data: data.data }))
      .catch(processValidationErrorsMessages);
  }

  updateMany(resource: string, params: UpdateManyParams) {
    return Promise.all(
      params.ids.map((id) =>
        this.axiosClient.put(
          `/${resource}/${id}`,
          params.data,
          this.prepareOptions()
        )
      )
    ).then((responses) => ({
      data: responses.map(({ data }) => data?.data?.id),
    }));
  }

  create(resource: string, params: CreateParams) {
    const defaultCreateFn = (bodyForm: any, basicOptions: any) =>
      this.axiosClient
        .post(`/${resource}`, bodyForm, this.prepareOptions(basicOptions))
        .then(({ data }) => {
          // console.log('create', data);
          return { data: { ...data, id: data?.id } };
        })
        .catch(processValidationErrorsMessages);

    if (resource === "fileupload" && params.data.fichero) {
      // Freshly dropped files are File objects send as form
      let formData = new FormData();
      formData.append("fichero", params.data.fichero.rawFile);
      return defaultCreateFn(formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        }
      });
    }
    // Default return
    return defaultCreateFn(params?.data, {});
  }

  deleteOne(resource: string, params: DeleteParams) {
    return this.axiosClient
      .delete(`/${resource}/${params?.id}`, this.prepareOptions())
      .then(({ data }) => ({ data: data.data }))
      .catch(processValidationErrorsMessages);
  }
  deleteMany(resource: string, params: DeleteManyParams) {
    return Promise.all(
      params.ids.map((id) =>
        this.axiosClient.delete(`/${resource}/${id}`, this.prepareOptions())
      )
    )
      .then((responses) => ({
        data: responses.map(({ data }) => data?.data?.id),
      }))
      .catch(processValidationErrorsMessages);
  }

  asRaProvider(): DataProvider {
    return {
      getList: (r, p) => this.getList(r, p),
      getOne: (r, p) => this.getOne(r, p),
      getMany: (r, p) => this.getMany(r, p),
      getManyReference: (r, p) => this.getManyReference(r, p),
      update: (r, p) => this.update(r, p),
      updateMany: (r, p) => this.updateMany(r, p),
      create: (r, p) => this.create(r, p),
      delete: (r, p) => this.deleteOne(r, p),
      deleteMany: (r, p) => this.deleteMany(r, p),
    };
  }
}

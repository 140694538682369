import {
  List,
  TextField,
  TextInput,
  Datagrid,
  ExportButton,
  CreateButton,
  TopToolbar,
  FilterButton,
  EmailField,
  DateField,
  RichTextField,
} from "react-admin";

import {
  ComunidadFilter,
  ProvinciaFilter,
} from "../Direcciones/DireccionesFilters";
import { EtiquetaFilter } from "../Etiquetas/EtiquetasFilters";
import { WithPermissions } from "../../lib/permissions";
import { PermissionsData } from "../../api-provider";
import { CargoFilter } from "../Cargos/CargoFilter";



const exporter = () => {
  // Vacio se auto descarga el fichero
};

const CustomAction = ({ className, filters }: any) =>
  <WithPermissions>
    {({ isEditor }: PermissionsData) => (
      <TopToolbar className={className}>
        <FilterButton filters={filters} />
        {isEditor && <CreateButton />}
        {isEditor && <ExportButton maxResults={10000} exporter={exporter} meta={{ exporter: true }} />}
      </TopToolbar>)}
  </WithPermissions>
  ;

const contactosFilters = [
  <TextInput label="Buscar" source="q" alwaysOn />,
  <ComunidadFilter source="comunidad_id" />,
  <ProvinciaFilter source="provincia_id" />,
  <CargoFilter source="cargo_id" />,
  <EtiquetaFilter label="Categoría" tipo="CATEGORIA" source="categoria_id" />,
  <EtiquetaFilter
    label="Subcategoría"
    tipo="SUBCATEGORIA"
    source="subcategoria_id"
  />,
  <EtiquetaFilter label="Tipología" tipo="TIPOLOGIA" source="tipologia_id" />,
];

export const ListaContactos = (props: any) => (
  <WithPermissions>
    {({ isEditor }: PermissionsData) => (
      <>
        <List
          {...props}
          title="Listado de contactos"
          resource="contactos"
          hasCreate={isEditor}
          
          perPage={25}
          empty={false}
          filters={contactosFilters}
          actions={<CustomAction />}
        >
          {/* Todo es edicion porque el lector tambien necesita poder editar aunque solo sea 1 campo */}
          <Datagrid rowClick={"edit"} size="small" optimized sx={{
            '& .RaDatagrid-rowCell': { padding: '0 5px 0 5px' },
          }}
            bulkActionButtons={isEditor ? undefined : false}
          
          >
            <TextField source="nombre" label="Nombre" />
            <TextField source="apellidos" label="Apellidos" />
            <TextField source="cargo" label="Cargo" />
            <TextField source="nombre_organizacion" label="Organización" />
            <TextField source="telefono" label="Teléfono del contacto"  sx={{ width: '250px' }} />
            <EmailField source="email" label="eMail del contacto" />
            <TextField source="categoria" label="Categoría" />
            <TextField source="subcategoria" label="Subcategoría" />
            <TextField source="tipologia" label="Tipología" />

            {/* <OrganizacionNombreLinkField
              label="Organización"
            /> */}
            <TextField source="provincia" label="Provincia" />
            <TextField source="comunidad" label="Comunidad" />

            <RichTextField source="observaciones" label="Observaciones" />
            <DateField source="updated_at" label="Modificado" />
          </Datagrid>
        </List>
        {/* <CreateDialog title="Crear nuevo contacto" fullWidth maxWidth="lg">
          <DialogContent>
            <ContactoCreateEditForm />
          </DialogContent>
        </CreateDialog>
        <EditDialog title="Editar contacto" fullWidth maxWidth="lg">
          <DialogContent>
            <ContactoCreateEditForm isEdit={true} />
          </DialogContent>
        </EditDialog> */}
        {/* Edicion especial en dialogo solo para lectores */}
        {/* {!isEditor && (
          <EditDialog
            title="Datos del contacto"
            fullWidth
            maxWidth="lg"
            queryOptions={{ meta: { 'refresh': true } }}
            >
            <DialogContent>
              <ContactoEditFormLector />
            </DialogContent>
          </EditDialog>
        )} */}
        {/* <ShowDialog title="Datos del contacto" fullWidth maxWidth="lg">
          <DialogContent>
            <ContactoShowForm />
          </DialogContent>
        </ShowDialog> */}
      </>
    )}
  </WithPermissions>
);

import {
  TextField,
  SimpleForm,
  DateField,
  Toolbar,
  SaveButton,
} from "react-admin";

import { Grid, Typography } from "@mui/material";
import { RichTextInput } from "ra-input-rich-text";

const ToolbarNoDelete = () => <Toolbar><SaveButton /></Toolbar>

export const ContactoEditFormLector = () => {

  return (
    <SimpleForm toolbar={<ToolbarNoDelete />}>
      <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
        <Grid item xs={12}>
          <Typography variant="h5" gutterBottom>
            Información de la persona
          </Typography>
        </Grid>
        <Grid item xs={6}>
          <strong>Fue creado: </strong><DateField source={`created_at`} /><br />
          <strong>Fue modificado: </strong><DateField source={`updated_at`} />
        </Grid>
        <Grid
          container
          item
          xs={12}
          direction="row"
          justifyContent="flex-start"
          alignItems="flex-start"
        >
          <Grid item xs={2}>
            <strong>Nombre contacto:</strong>
          </Grid>
          <Grid item xs>
            <TextField source="nombre" fullWidth />
            &nbsp;
            <TextField source="apellidos" fullWidth />
          </Grid>
        </Grid>
        <Grid
          container
          item
          xs={12}
          direction="row"
          justifyContent="flex-start"
          alignItems="flex-start"
        >
          <Grid item xs={2}>
            <strong>E-Mail:</strong>
          </Grid>
          <Grid item xs>
            <TextField source="email" fullWidth />
          </Grid>
        </Grid>
        <Grid container item xs={12}>
          <Grid item xs={2}>
            <strong>Teléfono y extensión:</strong>
          </Grid>
          <Grid item xs>
            <TextField source="telefono" fullWidth />
            <TextField source="extension" />
          </Grid>
          <Grid item xs={2}>
            <strong>Móvil:</strong>
          </Grid>
          <Grid item xs>
            <TextField source="otro_telefono" fullWidth />
          </Grid>
        </Grid>

        <Grid container item xs={12}>
          <Grid item xs={2}>
            <strong>Cargo que desempeña:</strong>
          </Grid>
          <Grid item xs>
            <TextField source="cargo.nombre" />
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <Grid item xs>
            <strong>Organización:</strong>
          </Grid>
          <Grid item xs>
            <TextField source="organizacion.nombre" />
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <Grid item xs>
            <strong>Tipologia</strong>
          </Grid>
          <Grid item xs>
            <TextField source="organizacion.tipologia.padre.padre.nombre" />
            &nbsp;&gt;&nbsp;
            <TextField source="organizacion.tipologia.padre.nombre" />
            &nbsp;&gt;&nbsp;
            <TextField source="organizacion.tipologia.nombre" />
          </Grid>
        </Grid>



        <Grid container item xs={12}>
          <Grid item xs={2}>
            <strong>Observaciones</strong>
          </Grid>
          <Grid item xs={12}>
            <RichTextInput
              source={`observaciones`}
              label=""
              fullWidth
            />
          </Grid>
        </Grid>

      </Grid>
    </SimpleForm>
  );
};

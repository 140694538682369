import {
  TextInput,
  ReferenceManyField,
  TextField,
  ReferenceInput,
  AutocompleteInput,
  SimpleForm,
  Datagrid,
  useRecordContext,
} from "react-admin";
import { Grid } from "@mui/material";

import { BoxCardWithTitle } from "../../theme/utils";

import { DireccionCreateEditInnerForm } from "../Direcciones/DireccionCreateEditInnerForm";

import { EditableDatagrid, RowForm } from "@react-admin/ra-editable-datagrid";
import { CrearCargo } from "../Cargos";
import { FullWidthTabs } from "../../lib/tabs";
import { SelectTipologiaInput } from "../Etiquetas/SelectTipologiaInput";
import { WithPermissions } from "../../lib/permissions";
import { PermissionsData } from "../../api-provider";
import { RichTextInput } from "ra-input-rich-text";

const ContactoFormInRowWithTabs = ({ organizacion_id, ...props }) => {
  return (
    <RowForm
      {...props}
      transform={(r: Object) => ({ ...r, contacto: { ...r, organizacion_id } })}
    >
      <TextField source="id" />
      <TextInput source="nombre" isRequired />
      <TextInput source="apellidos" />
      <TextInput source="email" isRequired />
      <TextInput source="telefono" />
      <TextInput source="otro_telefono" />

      <ReferenceInput source="cargo_id" reference="cargos" sort={{ field: 'nombre', order: 'ASC' }}>
        <AutocompleteInput
          isRequired
          create={<CrearCargo />}
          optionText="nombre"
          TextFieldProps={{
            placeholder: "Comienza a escribir para crear un nuevo valor",
          }}
        />
      </ReferenceInput>
    </RowForm>
  );
};

export const ListaContactosDeOrganizacion = () => {
  const organizacionRecord = useRecordContext();
  console.log(organizacionRecord);
  return (
    <ReferenceManyField
      reference="contactos"
      target="organizacion_id"
      label={false}
    >
      <WithPermissions>
        {({ isEditor }: PermissionsData) => {
          if (isEditor) {
            return (
              <EditableDatagrid
                mutationMode="undoable"
                editForm={
                  <ContactoFormInRowWithTabs
                    organizacion_id={organizacionRecord.id}
                  />
                }
                createForm={
                  <ContactoFormInRowWithTabs
                    organizacion_id={organizacionRecord.id}
                  />
                }
              >
                <TextField source="id" />
                <TextField source="nombre" />
                <TextField source="apellidos" />
                <TextField source="email" />
                <TextField source="telefono" />
                <TextField source="otro_telefono" />

                <TextField label="Cargo" source="cargo" />
              </EditableDatagrid>
            );
          } else {

            return (
              <Datagrid rowClick={false} size="small" empty={<p><strong>No hay contactos</strong></p>} bulkActionButtons={false}>
                <TextField source="id" />
                <TextField source="nombre" />
                <TextField source="apellidos" />
                <TextField source="email" />
                <TextField source="telefono" />
                <TextField source="otro_telefono" />

                <TextField label="Cargo" source="cargo" />
              </Datagrid>
            );
          }
        }}
      </WithPermissions>
    </ReferenceManyField>
  );
};

export const OrganizacionCreateEditBaseForm = ({
  isEdit = false,
  onSubmit = undefined,
}: {
  isEdit?: boolean;
  onSubmit?: (data: any) => void;
}) => {
  const sourcePrefix = isEdit ? `` : `organizacion.`;

  const otrosDatos = (
    <>
      <Grid item xs={12}>
        <BoxCardWithTitle title="Dirección de la organización">
          <DireccionCreateEditInnerForm
            sourceGroupName="direccion"
            isEdit={true}
          />
        </BoxCardWithTitle>
      </Grid>


      <RichTextInput
        source={`${sourcePrefix}observaciones`}
        label="Observaciones"
        fullWidth
      // defaultValue=" "
      />

    </>
  );

  return (
    <SimpleForm onSubmit={onSubmit} >
      <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
        <Grid item xs={12}>
          <TextInput
            source={`${sourcePrefix}nombre`}
            label="Nombre de la organización"
            isRequired
            fullWidth
          />
        </Grid>
        <Grid item xs={3}>
          <TextInput
            source={`${sourcePrefix}telefono`}
            label="Teléfono"
            isRequired
            fullWidth
          />
        </Grid>
        <Grid item xs={3}>
          <TextInput
            source={`${sourcePrefix}otro_telefono`}
            label="Otro Teléfono"
            fullWidth
          />
        </Grid>
        <Grid item xs={6}>
          <TextInput source={`${sourcePrefix}email`} label="email" fullWidth />
        </Grid>

        <Grid item xs={12}>
          <SelectTipologiaInput
            source={`${sourcePrefix}tipologia_id`}
            isRequired
          />

          {/* <SelectEtiquetaInput
            source={`${sourcePrefix}tipologia_id`}
            isRequired
            label="Tipología"
            filtrarTipo={EtiquetaTipos.Tipologia}
          /> */}
        </Grid>

        {!isEdit && otrosDatos}
        {isEdit && (
          <FullWidthTabs
            tabsData={[
              {
                label: "Otros datos",
                content: otrosDatos,
              },
              {
                label: "Contactos de la organización",
                content: <ListaContactosDeOrganizacion />,
              },
            ]}
          />
        )}
      </Grid>
    </SimpleForm>
  );
};

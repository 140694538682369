import { Skeleton } from "@mui/material";
import { PermissionsData } from "../api-provider";
import { usePermissions } from "react-admin";

export const isAdmin = (permissions: PermissionsData) =>
  permissions && permissions.isAdmin === true;

export const isEditor = (permissions: PermissionsData) =>
  (permissions && permissions.isAdmin === true) ||
  permissions.isEditor === true;

export const isLector = (permissions: PermissionsData) => !isEditor(permissions)

export const WithPermissions = ({ children, ...rest }) => {
  const {
    isLoading,
    permissions,
  }: { isLoading: boolean; permissions: PermissionsData } = usePermissions();
  if (isLoading) {
    return <Skeleton variant="rectangular" width="100%" height={200} />;
  } else {
    return children({ ...rest, ...permissions });
  }
};

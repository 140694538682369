import { Create, Edit } from "react-admin";
import { ContactoCreateEditForm } from "./ContactoCreateEditForm";
import { PermissionsData } from "../../api-provider";
import { WithPermissions } from "../../lib/permissions";
import { ContactoEditFormLector } from "./ContactoEditFormLector";


const CreateContacto = () => {
    return <Create>
        <ContactoCreateEditForm isEdit={false} />
    </Create>
};

const EditContacto = () =>

    <WithPermissions>
        {({ isEditor }: PermissionsData) => {
            if (isEditor) {
                return <Edit>
                    <ContactoCreateEditForm isEdit={true} />
                </Edit>
            } else {
                // Si es lector edicion de lector
                return (
                    <Edit queryOptions={{ meta: { 'refresh': true } }}>
                        <ContactoEditFormLector />
                    </Edit>
                );
            }
        }}
    </WithPermissions>

    ;

export {
    CreateContacto,
    EditContacto,
}
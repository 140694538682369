import {
  ReferenceInput,
  AutocompleteInput,
  useGetList,
  SelectInput,
} from "react-admin";
import { GridColumnSeparator } from "../../theme/utils";
import { Grid } from "@mui/material";
import React, { useEffect } from "react";
import { isEmpty, toNumber } from "lodash";

export const SelectPais = ({
  setValue,
  isRequired = false,
  sourceGroupName = undefined,
}: {
  setValue: (v: string) => void;
  isRequired?: boolean;
  sourceGroupName?: string;
}) => {
  const { data, isLoading } = useGetList("paises", {
    sort: {
      field: "pais",
      order: "ASC",
    },
    pagination: { page: 1, perPage: 10000 },
  });
  return (
    <SelectInput
      isRequired={isRequired}
      options={{
        label: "País",
      }}
      source={
        isEmpty(sourceGroupName) ? `pais_id` : `${sourceGroupName}.pais_id`
      }
      choices={data ? data : []}
      optionText="pais"
      optionValue="id"
      isLoading={isLoading}
      onChange={(e) => setValue(e.target.value)}
      fullWidth
    />
  );
};

export const SelectComunidad = ({
  setValue,
  filterByPaisId,
  isRequired = false,
  sourceGroupName = undefined,
}: {
  setValue: (v: string) => void;
  filterByPaisId?: number;
  isRequired?: boolean;
  sourceGroupName?: string;
}) => {
  const filter = filterByPaisId ? { pais_id: filterByPaisId } : undefined;

  const { data, isLoading } = useGetList("comunidades", {
    sort: {
      field: "comunidad",
      order: "ASC",
    },
    filter,
    pagination: { page: 1, perPage: 10000 },
  });

  return (
    <SelectInput
      isRequired={isRequired}
      // disabled={!filterByPaisId}
      options={{
        label: "Comunidad",
      }}
      source={
        isEmpty(sourceGroupName)
          ? `comunidad_id`
          : `${sourceGroupName}.comunidad_id`
      }
      choices={data ? data : []}
      optionText="comunidad"
      optionValue="id"
      isLoading={isLoading}
      onChange={(e) => setValue(e.target.value)}
      fullWidth
    />
  );
};

export const SelectProvincia = ({
  setValue,
  filterByComunidadId,
  isRequired = false,
  sourceGroupName = undefined,
}: {
  setValue: (v: string) => void;
  filterByComunidadId?: number;
  isRequired?: boolean;
  sourceGroupName?: string;
}) => {
  const filter = filterByComunidadId
    ? { comunidad_id: filterByComunidadId }
    : undefined;
  const { data, isLoading } = useGetList("provincias", {
    sort: {
      field: "provincia",
      order: "ASC",
    },
    filter,
    pagination: { page: 1, perPage: 10000 },
  });
  return (
    <SelectInput
      isRequired={isRequired}
      // disabled={!filterByComunidadId}
      options={{
        label: "Provincia",
      }}
      source={
        isEmpty(sourceGroupName)
          ? `provincia_id`
          : `${sourceGroupName}.provincia_id`
      }
      choices={data ? data : []}
      optionText="provincia"
      optionValue="id"
      isLoading={isLoading}
      onChange={(e) => setValue(e.target.value)}
      fullWidth
    />
  );
};

export const SelectMunicipioInput = ({
  label = "Municipio",
  sourceGroupName = undefined,
  source = undefined,
  isRequired = false,
  filtrarByProvinciaId = undefined,
}: {
  label?: string;
  source?: string;
  sourceGroupName?: string;
  isRequired?: boolean;
  filtrarByProvinciaId?: number;
}) => (
  <ReferenceInput
    source={
      isEmpty(sourceGroupName)
        ? source || `municipio_id`
        : !isEmpty(source)
        ? `${sourceGroupName}.${source}`
        : `${sourceGroupName}.municipio_id`
    }
    reference="municipios"
    filter={filtrarByProvinciaId ? { provincia_id: filtrarByProvinciaId } : {}}
    perPage={100}
    sort={{
      field: "municipio",
      order: "ASC",
    }}
  >
    <AutocompleteInput
      // disabled={!filtrarByProvinciaId}
      label={label}
      isRequired={isRequired}
      suggestionLimit={20}
      fullWidth
    />
  </ReferenceInput>
);

export const SelectPoblacionInput = ({
  source = undefined,
  sourceGroupName,
  isRequired = false,
}: {
  sourceGroupName: string;
  source?: string;
  isRequired?: boolean;
  isEdit?: boolean;
}) => {
  const [pais, setPais] = React.useState<string | undefined>(undefined);
  const [comunidad, setComunidad] = React.useState<string | undefined>(
    undefined
  );
  const [provincia, setProvincia] = React.useState<string | undefined>(
    undefined
  );

  useEffect(() => {
    setComunidad(undefined);
  }, [pais]);

  useEffect(() => {
    setProvincia(undefined);
  }, [comunidad]);

  return (
    <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
      <Grid item xs={3}>
        <SelectPais
          setValue={setPais}
          sourceGroupName={sourceGroupName}
          isRequired
        />
      </Grid>
      <GridColumnSeparator cols={1} />
      <Grid item xs={4}>
        <SelectComunidad
          sourceGroupName={sourceGroupName}
          isRequired
          setValue={setComunidad}
          filterByPaisId={pais ? toNumber(pais) : undefined}
        />
      </Grid>
      <GridColumnSeparator cols={1} />
      <Grid item xs={3}>
        <SelectProvincia
          sourceGroupName={sourceGroupName}
          isRequired
          setValue={setProvincia}
          filterByComunidadId={comunidad ? toNumber(comunidad) : undefined}
        />
      </Grid>
      <Grid item xs={12}>
        <SelectMunicipioInput
          source={source}
          sourceGroupName={sourceGroupName}
          isRequired={isRequired}
          filtrarByProvinciaId={provincia ? toNumber(provincia) : undefined}
        />
      </Grid>
    </Grid>
  );
};

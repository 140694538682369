import { TextField, SimpleShowLayout, SimpleForm, RichTextField } from "react-admin";
import { Grid, Typography } from "@mui/material";
import { DireccionShowInlineForm } from "../Direcciones/DireccionShowInlineForm";
import { ListaContactosDeOrganizacion } from "./OrganizacionCreateEditBaseForm";
import { EditInDialogButton } from "@react-admin/ra-form-layout";
import { RichTextInput } from "ra-input-rich-text";

export const OrganizacionShowForm = () => {
  return (
    <SimpleShowLayout>
      <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
        <Grid item xs={12}>
          <TextField source="nombre" fullWidth sx={{ fontSize: '1.3em', fontWeight: 'bold' }} />
        </Grid>

        {/* <Grid item xs={12}>
          <strong>ID:</strong>
          <TextField source="id" />
          &nbsp;
        </Grid> */}

        <Grid item xs={12}>
          <strong>Teléfono:</strong>&nbsp;<TextField source="telefono" fullWidth />
        </Grid>

        <Grid item xs={12}>
          <strong>Otro Teléfono:</strong>&nbsp; <TextField source="otro_telefono" fullWidth />
        </Grid>

        <Grid item xs={12}>
          <strong>Email:</strong>&nbsp; <TextField source="email" fullWidth />
        </Grid>

        <Grid item xs={12}>
          <strong>Tipología:&nbsp;&nbsp;</strong>
          <TextField source="tipologia.padre.padre.nombre" label="Categoría" />
          &nbsp;&gt;&nbsp;
          <TextField source="tipologia.padre.nombre" label="Subcategoría" />
          &nbsp;&gt;&nbsp;
          <TextField source="tipologia.nombre" label="Tipología" />
        </Grid>
        <Grid item xs={12}>
          <DireccionShowInlineForm />
        </Grid>

        <Grid item xs={4}>
          <strong>Observaciones:</strong>&nbsp;
        </Grid>
        <Grid item xs={4}>
          <EditInDialogButton fullWidth maxWidth="md">
            <SimpleForm>
              <RichTextInput source="observaciones" fullWidth />
            </SimpleForm>
          </EditInDialogButton>
        </Grid>
        <Grid item xs={12}>

          <RichTextField source="observaciones" fullWidth />

        </Grid>
        <Grid item xs={12}>
          <Typography variant="h6">Contactos asociados:</Typography>
          <ListaContactosDeOrganizacion />
        </Grid>
      </Grid>
    </SimpleShowLayout>
  );
};

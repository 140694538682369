import { AppBarProps, ThemesContext } from "@react-admin/ra-enterprise";
import { useContext } from "react";
import {
  TitlePortal,
  ToggleThemeButton,
  AppBar as DefaultAppBar,
} from "react-admin";
import { LogoIntervento } from "../theme/Logo";
import { Box } from "@mui/material";

export const CustomAppBar = (props: AppBarProps) => {
  
  const { darkTheme, lightTheme } = useContext(ThemesContext);

  return (
    <DefaultAppBar {...props} elevation={1} sx={{ backgroundColor: '#121212' }} >
      <TitlePortal />
      
      <LogoIntervento sx={{width: '205px'}} />
      <Box component="span" flex={1} />
      

      <ToggleThemeButton darkTheme={darkTheme} lightTheme={lightTheme} />
    </DefaultAppBar>
  );
};

import { SvgIcon } from "@mui/material";

export const LogoIntervento = (props: any) => (
  <SvgIcon {...props} viewBox="0 0 205 35">
    <svg
      width="205"
      height="35"
      viewBox="0 0 205 35"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g>
        <path
          d="M0 34.6019V0.39209H5.69288V34.5957L0 34.6019Z"
          fill="#FF564E"
        />
        <path
          d="M25.4394 34.6019L16.2341 13.4733H16.0276V34.6019H10.5825V0.39209H16.2258L25.0347 20.4753H25.2412V0.39209H30.6863V34.5957L25.4394 34.6019Z"
          fill="#FF564E"
        />
        <path
          d="M40.0401 34.6019V5.66853H33.2095V0.39209H52.4625V5.66231H45.6814V34.5957L40.0401 34.6019Z"
          fill="#FF564E"
        />
        <path
          d="M55.0354 34.6019V0.39209H71.2696V5.51298H60.6787V14.7592H69.8344V19.8884H60.6787V29.4831H71.2696V34.6039L55.0354 34.6019Z"
          fill="#FF564E"
        />
        <path
          d="M89.137 34.6019L85.4739 22.1242H80.6234V34.6019H74.9822V0.39209H85.9694C88.3441 0.39209 90.1336 0.806905 91.3381 1.63653C92.5479 2.47488 93.4103 3.72913 93.7623 5.16246C94.1753 6.68345 94.3818 8.70498 94.3818 11.2271C94.3818 13.68 94.1423 15.7181 93.6632 17.3414C93.457 18.1078 93.0933 18.8224 92.5954 19.439C92.0974 20.0555 91.4764 20.5605 90.7724 20.9213L95.0777 34.5936L89.137 34.6019ZM87.355 16.5305C87.9166 16.2152 88.2876 15.6683 88.4679 14.8899C88.6483 14.1114 88.7391 12.8932 88.7405 11.2354C88.7405 9.5761 88.6497 8.36622 88.4679 7.60573C88.2862 6.84523 87.9153 6.30666 87.355 5.99001C86.7933 5.67752 85.8862 5.52058 84.6335 5.5192H80.6234V17.0033H84.6335C85.8875 17.0033 86.7947 16.8457 87.355 16.5305Z"
          fill="#FF564E"
        />
        <path
          d="M102.749 34.6019L95.6208 0.39209H101.403L106.749 28.3319H107.095L112.4 0.39209H118.041L110.915 34.5957L102.749 34.6019Z"
          fill="#FF564E"
        />
        <path
          d="M120.567 34.6019V0.39209H136.801V5.51298H126.208V14.7592H135.366V19.8884H126.208V29.4831H136.801V34.6039L120.567 34.6019Z"
          fill="#FF564E"
        />
        <path
          d="M155.36 34.6019L146.153 13.4733H145.957V34.6019H140.511V0.39209H146.153L154.964 20.4753H155.17V0.39209H160.615V34.5957L155.36 34.6019Z"
          fill="#FF564E"
        />
        <path
          d="M169.959 34.6019V5.66853H163.13V0.39209H182.383V5.66231H175.602V34.5957L169.959 34.6019Z"
          fill="#FF564E"
        />
        <path
          d="M187.479 33.5315C186.06 32.5539 185.169 30.9465 184.807 28.7092C184.445 26.472 184.264 22.7387 184.262 17.5092C184.262 12.2743 184.444 8.53747 184.807 6.29885C185.171 4.06023 186.061 2.45283 187.479 1.47663C188.897 0.500431 191.273 0.0116395 194.607 0.0102568C197.941 0.00887412 200.317 0.497666 201.735 1.47663C203.155 2.45559 204.053 4.07129 204.432 6.32374C204.811 8.57618 205.001 12.3047 205.002 17.5092C205.002 22.7124 204.812 26.4416 204.432 28.6968C204.052 30.952 203.153 32.567 201.735 33.5418C200.315 34.5208 197.939 35.0103 194.607 35.0103C191.276 35.0103 188.9 34.5173 187.479 33.5315ZM197.924 29.2568C198.518 28.842 198.906 27.8478 199.086 26.2743C199.266 24.7007 199.357 21.7756 199.359 17.4988C199.359 13.2235 199.268 10.2991 199.086 8.72552C198.904 7.15199 198.517 6.15781 197.924 5.743C197.329 5.32819 196.223 5.12078 194.607 5.12078C192.991 5.12078 191.894 5.32819 191.316 5.743C190.738 6.15781 190.358 7.14369 190.178 8.70063C189.998 10.2576 189.907 13.1903 189.906 17.4988C189.906 21.8088 189.996 24.7422 190.178 26.2992C190.36 27.8561 190.739 28.842 191.316 29.2568C191.891 29.6716 192.988 29.879 194.607 29.879C196.226 29.879 197.332 29.6716 197.924 29.2568Z"
          fill="#FF564E"
        />
      </g>
      <defs>
        <clipPath id="clip0_634_1444">
          <rect width="205" height="35" fill="white" />
        </clipPath>
      </defs>
    </svg>
  </SvgIcon>
);

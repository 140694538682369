import {
  List,
  TextField as TextFieldRa,
  Datagrid,
  TextInput,
} from "react-admin";
import { WithPermissions } from "../../lib/permissions";
import { EditableDatagrid, RowForm } from "@react-admin/ra-editable-datagrid";
import { PermissionsData } from "../../api-provider";
import { SelectPais } from "../Direcciones/SelectPoblacionInput";

const comunidadesFilters = [<TextInput label="Buscar" source="q" alwaysOn />];

export const ListaComunidades = () => (
  <WithPermissions>
    {({ isEditor }: PermissionsData) => (
      <List
        hasCreate={isEditor}
        exporter={isEditor ? undefined : false}
        empty={false}
        filters={comunidadesFilters}
      >
        {isEditor && (
          <EditableDatagrid
            mutationMode="undoable"
            editForm={<ComunidadForm />}
            createForm={<ComunidadForm />}
            size="small"
          >
            <TextFieldRa source="id" />
            <TextFieldRa source="comunidad" fullWidth  />
            <TextFieldRa source="pais.pais" fullWidth label="País" />
          </EditableDatagrid>
        )}
        {!isEditor && (
          <Datagrid rowClick={false} size="small">
            <TextFieldRa source="id" />
            <TextFieldRa source="comunidad" fullWidth />
            <TextFieldRa source="pais.pais" fullWidth  label="País" />
          </Datagrid>
        )}
      </List>
    )}
  </WithPermissions>
);

const ComunidadForm = () => (
  <RowForm>
    <TextFieldRa source="id" />
    <TextInput source="comunidad" isRequired fullWidth />
    <SelectPais isRequired setValue={() => {}} />
  </RowForm>
);

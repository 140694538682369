import spanishMessages from "@blackbox-vision/ra-language-spanish";
import { merge } from "lodash";
import polyglotI18nProvider from "ra-i18n-polyglot";

const extendedMessages = merge(
  {
    ra: {
      configurable: {
        customize: "Personalizar",
      },
      action: {
        create_item: "Crear",
      },
    },
  },
  spanishMessages
);

export const i18nProvider = polyglotI18nProvider(() => extendedMessages, "es", [
  "es",
]);

import {
  TextField,
  TextInput,
  ReferenceInput,
  AutocompleteInput,
  SimpleForm,
  DateField,
} from "react-admin";

import { CrearCargo } from "../Cargos";
import { CrearOrganizacion } from "../Organizacion";
import { Grid, Typography } from "@mui/material";
import {
  BoxCardWithTitle,
  GridColumnSeparator,
  GridRowSeparator,
} from "../../theme/utils";
import { representacionOrganizacion } from "../../lib/tipos";
import { RichTextInput } from "ra-input-rich-text";

export const ContactoCreateEditForm = ({
  isEdit = false,
}: {
  isEdit?: boolean;
}) => {
  const sourcePrefix = isEdit ? `` : `contacto.`;

  return (
    <SimpleForm>
      <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
        <Grid item xs={12}>
          <Typography variant="body1" gutterBottom>
            Información de la persona
          </Typography>
        </Grid>
        <Grid item xs={6}>
          ID en la base de datos: <TextField source={`${sourcePrefix}id`} />
        </Grid>
        <Grid item xs={6}>
          Fue creado: <DateField source={`${sourcePrefix}created_at`} /><br />
          Fue modificado: <DateField source={`${sourcePrefix}updated_at`} />
        </Grid>
        <Grid item xs={5}>
          <TextInput source={`${sourcePrefix}nombre`} isRequired fullWidth />
        </Grid>
        <GridColumnSeparator />
        <Grid item xs={6}>
          <TextInput source={`${sourcePrefix}apellidos`} fullWidth />
        </Grid>
        <Grid item xs={12}>
          <TextInput source={`${sourcePrefix}email`} isRequired fullWidth />
        </Grid>
        <Grid item xs={4}>
          <TextInput source={`${sourcePrefix}telefono`} fullWidth />
        </Grid>
        <Grid item xs={4}>
          <TextInput source={`${sourcePrefix}extension`} fullWidth />
        </Grid>
        <Grid item xs={4}>
          <TextInput source={`${sourcePrefix}otro_telefono`} fullWidth />
        </Grid>

        <Grid item xs={12}>
          <BoxCardWithTitle title="Selecciona la organización a la que pertenece el contacto">
            <ReferenceInput
              source={`${sourcePrefix}organizacion_id`}
              reference="organizaciones"
              sort={{ field: "nombre", order: "ASC" }}
            >
              <AutocompleteInput
                create={<CrearOrganizacion />}
                optionText={representacionOrganizacion}
                TextFieldProps={{
                  placeholder: "Comienza a escribir para crear un nuevo valor",
                }}
                helperText="Comienza a escribir para crear un nuevo valor"
                isRequired
              />
            </ReferenceInput>
          </BoxCardWithTitle>
        </Grid>
        <GridRowSeparator />
        <Grid item xs={12}>
          <ReferenceInput
            source={`${sourcePrefix}cargo_id`}
            reference="cargos"
            sort={{ field: "nombre", order: "ASC" }}
          >
            <AutocompleteInput
              label="Cargo del contacto en la empresa"
              create={<CrearCargo />}
              optionText="nombre"
              TextFieldProps={{
                placeholder: "Comienza a escribir para crear un nuevo valor",
              }}
              isRequired
            />
          </ReferenceInput>
        </Grid>
        <GridRowSeparator />
        <Grid item xs={12}>
          <RichTextInput
            source={`${sourcePrefix}observaciones`}
            label="Observaciones"
            fullWidth
          />
        </Grid>

        {/* 
          // LO HEMOS QUITADO PORQUE YO CREO QUE NO LO USAN PARA NADA
        <OptionalSwitch label="Dirección del contacto diferente a la de la organización">
          <BoxCard>
            <DireccionCreateEditInnerForm sourceGroupName={`${sourcePrefix}direccion`} />
          </BoxCard>
        </OptionalSwitch> */}
      </Grid>
    </SimpleForm>
  );
};

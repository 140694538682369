// import "./App.css";
import { Resource } from "react-admin";
import { Admin } from "@react-admin/ra-enterprise";
import {
  ThemesContext,
  defaultLightTheme,
  defaultDarkTheme,
} from "@react-admin/ra-enterprise";
import PersonIcon from "@mui/icons-material/Person";
import MapsHomeWorkIcon from "@mui/icons-material/MapsHomeWork";
import PublicIcon from '@mui/icons-material/Public';

import { axiosApi, axiosBase } from "./lib/axios";
import {
  AxiosProvider,
  AxiosAuthProvider,
  PermissionsData,
} from "./api-provider";
import { QueryClient } from "react-query";
import { Dashboard } from "./views/Dashboard";
import { ListaOrganizacion } from "./views/Organizacion";
import { ListaEtiquetas } from "./views/Etiquetas";
import { ListaContactos } from "./views/Contactos";
import { representacionDireccion, representacionMunicipio } from "./lib/tipos";

import { i18nProvider } from "./lib/translations";
import MainLayout from "./layouts/MainLayout";
import { ListaCargos } from "./views/Cargos";
import { ListaPaises } from "./views/Paises/Paises";
import { ListaProvincias } from "./views/Provincias/Provincias";
import { ListaComunidades } from "./views/Comunidades/Comunidades";
import { ListaMunicipios } from "./views/Municipios/Municipios";
import { CssBaseline } from "@mui/material";
import { CreateContacto, EditContacto } from "./views/Contactos/CreateEditContacto";
import { CreateOrganizacion, EditOrganizacion } from "./views/Organizacion/CreateEditOrganizacion";

const axiosProvider = new AxiosProvider(axiosApi);
const authProvider = new AxiosAuthProvider(axiosBase, "/");

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      staleTime: 15 * 1000,
    },
  },
});

function App() {
  return (
    <ThemesContext.Provider
      value={{ lightTheme: defaultLightTheme, darkTheme: defaultDarkTheme }}
    >
      <CssBaseline />
      <Admin
        disableTelemetry
        locale="es"
        i18nProvider={i18nProvider}
        dataProvider={axiosProvider.asRaProvider()}
        dashboard={Dashboard}
        authProvider={authProvider.asRaProvider()}
        requireAuth
        layout={MainLayout}
        queryClient={queryClient}
      >
        {({ isAdmin, isEditor }: PermissionsData) => [
          <Resource
            name="contactos"
            list={ListaContactos}
            create={isEditor ? CreateContacto : undefined}
            edit={EditContacto}
            hasCreate={isEditor}
            hasEdit={isEditor}
            icon={PersonIcon}
          />,
          <Resource
            name="organizaciones"
            list={ListaOrganizacion}
            create={isEditor ? CreateOrganizacion : undefined}
            edit={isEditor ? EditOrganizacion : undefined}
            hasCreate={isEditor}
            hasEdit={isEditor}
            recordRepresentation={(record: any) => `${record.nombre}`}
            icon={MapsHomeWorkIcon}
          />,
          <Resource
            name="cargos"
            list={isEditor || isAdmin ? ListaCargos : undefined}
            hasCreate={isEditor}
            hasEdit={isEditor}
            recordRepresentation={(record: any) => `${record.nombre}`}
          />,
          <Resource
            name="etiquetas"
            list={isAdmin ? ListaEtiquetas : undefined}
            hasCreate={isEditor}
            hasEdit={isEditor}
            options={{
              label: "Tipologías",
            }}
          />,
          <Resource
            name="municipios"
            list={isAdmin ? ListaMunicipios : undefined}
            hasCreate={isEditor}
            hasEdit={isEditor}
            recordRepresentation={representacionMunicipio}
            icon={PublicIcon}
          />,
          <Resource
            name="provincias"
            list={isAdmin ? ListaProvincias : undefined}
            hasCreate={isEditor}
            hasEdit={isEditor}
            icon={PublicIcon}
          />,
          <Resource
            name="comunidades"
            list={isAdmin ? ListaComunidades : undefined}
            hasCreate={isEditor}
            hasEdit={isEditor}
            icon={PublicIcon}
          />,
          <Resource
            name="paises"
            list={isAdmin ? ListaPaises : undefined}
            hasCreate={isEditor}
            hasEdit={isEditor}
            icon={PublicIcon}
          />,
          <Resource
            name="direcciones"
            hasCreate={isEditor}
            hasEdit={isEditor}
            recordRepresentation={representacionDireccion}
          />,
        ]}
      </Admin>
    </ThemesContext.Provider>
  );
}

export default App;

import React from "react";

import {
  useCreate,
  useCreateSuggestionContext,
  List,
  TextField as TextFieldRa,
  Datagrid,
  TextInput,
} from "react-admin";
import {
  Alert,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  TextField,
} from "@mui/material";
import { get } from "lodash";
import { WithPermissions } from "../../lib/permissions";
import { EditableDatagrid, RowForm } from "@react-admin/ra-editable-datagrid";
import { PermissionsData } from "../../api-provider";

export const CrearCargo = () => {
  const { filter, onCancel, onCreate } = useCreateSuggestionContext();
  const [create, { isLoading, error, isError }] = useCreate();
  const [value, setValue] = React.useState(filter || "");

  const handleSubmit = (event: any) => {
    event.preventDefault();
    create(
      "cargos",
      { data: { nombre: value } },
      {
        onSuccess: (data: any) => {
          setValue("");
          onCreate(data.data);
        },
      }
    );
  };

  return (
    <Dialog open onClose={onCancel}>
      <form onSubmit={handleSubmit}>
        <DialogContent>
          <TextField
            label="Nuevo cargo"
            value={value}
            onChange={(event) => setValue(event.target.value)}
            autoFocus
          />
          {isError && (
            <Alert severity="error">{`${get(error, "message")}`}</Alert>
          )}
        </DialogContent>
        <DialogActions>
          <Button disabled={isLoading} type="submit">
            Guardar
          </Button>
          <Button onClick={onCancel}>Cancel</Button>
        </DialogActions>
      </form>
    </Dialog>
  );
};

const cargosFilters = [<TextInput label="Buscar" source="q" alwaysOn />];

export const ListaCargos = () => (
  <WithPermissions>
    {({ isEditor }: PermissionsData) => (
      <List
        hasCreate={isEditor}
        exporter={isEditor ? undefined : false}
        empty={false}
        filters={cargosFilters}
        perPage={25}
      >
        {isEditor && (
          <EditableDatagrid
            mutationMode="undoable"
            editForm={<CargoForm />}
            createForm={<CargoForm />}
            size="small"
          >
            <TextFieldRa source="id" />
            <TextFieldRa source="nombre" fullWidth />
          </EditableDatagrid>
        )}
        {!isEditor && (
          <Datagrid rowClick={false} size="small">
            <TextFieldRa source="id" />
            <TextFieldRa source="nombre" fullWidth />
          </Datagrid>
        )}
      </List>
    )}
  </WithPermissions>
);

const CargoForm = () => (
  <RowForm>
    <TextFieldRa source="id" />
    <TextInput source="nombre" isRequired fullWidth />
  </RowForm>
);

import {
  List,
  SelectField,
  TextField,
  SelectInput,
  TextInput,
  FunctionField,
  Datagrid,
} from "react-admin";
import { EditableDatagrid, RowForm } from "@react-admin/ra-editable-datagrid";
import { EtiquetasTiposArray } from "../../lib/constantes";
import { SelectEtiquetaInput } from "./SelectEtiquetaInput";
import { Tipologia } from "../../lib/tipos";
import { WithPermissions } from "../../lib/permissions";
import { PermissionsData } from "../../api-provider";

const etiquetaFilter = [<TextInput label="Buscar" source="q" alwaysOn />];

export const ListaEtiquetas = () => (
  <WithPermissions>
    {({ isEditor }: PermissionsData) => (
      <List
        hasCreate={isEditor}
        exporter={isEditor ? undefined : false}
        empty={false}
        filters={etiquetaFilter}
        perPage={25}
      >
        {isEditor && (
          <EditableDatagrid
            mutationMode="undoable"
            editForm={<EtiquetaForm />}
            createForm={<EtiquetaForm />}
            size="small"
          >
            <TextField source="id" />

            <FunctionField
              label="Padre"
              render={(r: Tipologia) =>
                r.padre ? `(${r.padre.id}) - ${r.padre.nombre}` : ""
              }
            />

            <TextField source="nombre" />
            <SelectField
              source="tipo"
              label="Tipo"
              choices={EtiquetasTiposArray}
            />
          </EditableDatagrid>
        )}
        {!isEditor && (
          <Datagrid rowClick={false} size="small">
            <TextField source="id" />
            <FunctionField
              label="Padre"
              render={(r: Tipologia) =>
                r.padre ? `(${r.padre.id}) - ${r.padre.nombre}` : ""
              }
            />

            <TextField source="nombre" />
            <SelectField
              source="tipo"
              label="Tipo"
              choices={EtiquetasTiposArray}
            />
          </Datagrid>
        )}
      </List>
    )}
  </WithPermissions>
);

const EtiquetaForm = () => (
  <RowForm>
    <TextField source="id" />
    <SelectEtiquetaInput label="Padre" source="padre_id" isRequired />
    <TextInput source="nombre" isRequired />
    <SelectInput
      source="tipo"
      label="Tipo"
      choices={EtiquetasTiposArray}
      isRequired
    />
  </RowForm>
);

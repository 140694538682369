import React from "react";
import {
  useGetList,
  SelectArrayInput,
  useListFilterContext,
} from "react-admin";
import { get } from "lodash";

export const ComunidadFilter = ({ source }: { source: string }) => {
  const { filterValues, setFilters } = useListFilterContext();

  const initialValues = React.useMemo(
    () => ({
      [source]: filterValues[source],
      pais_id: get(filterValues, "pais_id", undefined),
    }),
    [filterValues, source]
  );

  const { data, isLoading } =
    useGetList("comunidades", {
      filter: initialValues,
      pagination: { page: 1, perPage: 10000 },
      sort: {
        field: 'comunidad',
        order: 'ASC'
      }
    });

  const onSearchChange = (event) => {
    if (event.target) {
      setFilters({ ...filterValues, [source]: event.target.value }, null);
    } else {
      const { [source]: _, ...filters } = filterValues;
      setFilters(filters, null);
    }
  };

  return (
    <SelectArrayInput
      options={{
        label: "Comunidad",
      }}
      source={source}
      choices={data ? data : []}
      optionText="comunidad"
      optionValue="id"
      isLoading={isLoading}
      onChange={onSearchChange}
      fullWidth
    />
  );
};

export const ProvinciaFilter = ({ source }: { source: string }) => {
  const { filterValues, setFilters } = useListFilterContext();

  const initialValues = React.useMemo(
    () => ({
      [source]: filterValues[source],
      comunidad_id: get(filterValues, "comunidad_id", undefined),
    }),
    [filterValues, source]
  );

  const { data, isLoading } =
    useGetList("provincias", {
      filter: initialValues,
      pagination: { page: 1, perPage: 10000 },
      sort: {
        field: 'provincia',
        order: 'ASC'
      }
    });

  const onSearchChange = (event) => {
    if (event.target) {
      setFilters({ ...filterValues, [source]: event.target.value }, null);
    } else {
      const { [source]: _, ...filters } = filterValues;
      setFilters(filters, null);
    }
  };

  return (
    <SelectArrayInput
      options={{
        label: "Provincia",
      }}
      source={source}
      choices={data ? data : []}
      optionText="provincia"
      optionValue="id"
      isLoading={isLoading}
      onChange={onSearchChange}
      fullWidth
    />
  );
};

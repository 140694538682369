import {
  List,
  TextField as TextFieldRa,
  Datagrid,
  TextInput,
} from "react-admin";

import { WithPermissions } from "../../lib/permissions";
import { EditableDatagrid, RowForm } from "@react-admin/ra-editable-datagrid";
import { PermissionsData } from "../../api-provider";
import {
  SelectProvincia,
} from "../Direcciones/SelectPoblacionInput";
import { ProvinciaFilter } from "../Direcciones/DireccionesFilters";

const municipiosFilters = [
  <TextInput label="Buscar" source="q" alwaysOn />,
  <ProvinciaFilter source="provincia_id" />,
];

export const ListaMunicipios = () => (
  <WithPermissions>
    {({ isEditor }: PermissionsData) => (
      <List
        hasCreate={isEditor}
        exporter={isEditor ? undefined : false}
        empty={false}
        filters={municipiosFilters}
      >
        {isEditor && (
          <EditableDatagrid
            mutationMode="undoable"
            editForm={<MunicipioForm />}
            createForm={<MunicipioForm />}
            size="small"
          >
            <TextFieldRa source="id" />
            <TextFieldRa source="municipio" fullWidth />
            <TextFieldRa
              source="provincia.provincia"
              fullWidth
              label="Provincia"
            />
            <TextFieldRa
              source="provincia.comunidad.comunidad"
              fullWidth
              label="Comunidad"
            />
            <TextFieldRa
              source="provincia.comunidad.pais.pais"
              fullWidth
              label="País"
            />
          </EditableDatagrid>
        )}
        {!isEditor && (
          <Datagrid rowClick={false} size="small">
            <TextFieldRa source="id" />
            <TextFieldRa source="municipio" fullWidth />
            <TextFieldRa
              source="provincia.provincia"
              fullWidth
              label="Provincia"
            />
            <TextFieldRa
              source="provincia.comunidad.comunidad"
              fullWidth
              label="Comunidad"
            />
            <TextFieldRa
              source="provincia.comunidad.pais.pais"
              fullWidth
              label="País"
            />
          </Datagrid>
        )}
      </List>
    )}
  </WithPermissions>
);

const MunicipioForm = () => (
  <RowForm>
    <TextFieldRa source="id" />
    <TextInput source="municipio" isRequired fullWidth />
    <SelectProvincia isRequired setValue={() => {}} />
    <></>
    <></>
  </RowForm>
);

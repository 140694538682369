import { chain, get } from "lodash";
import axios from "axios";
import {
  HttpError,
} from "react-admin";
import { AuthData, PermissionsData } from "./auth-provider";
/**
 * Build errors messages on validations if any
 *
 * @param {HttpError} httpError
 * @returns HttpError
 */
export const processValidationErrorsMessages = (httpError: any) => {
  // console.log('httpError', httpError);
  if (httpError instanceof HttpError && get(httpError, "body.errors")) {
    return Promise.reject(
      new HttpError(
        chain(httpError.body.errors)
          .toPairs()
          .map(([k, v]) => `${k}: ${v}`)
          .join("<br />")
          .value(),
        httpError.status,
        httpError.body
      )
    );
  }
  if (axios.isAxiosError(httpError)) {
    return Promise.reject(
      new HttpError(
        get(httpError, 'response.data.message') || httpError.message || "Error general",
        httpError.status,
        httpError.toJSON()
      )
    );
  }
  // Default invalid format
  return Promise.reject(new HttpError(httpError, 400, {}));
};



export function getAuthData(): AuthData | undefined {
  const auth = sessionStorage.getItem("auth");
  if (auth) {
    return JSON.parse(auth);
  }
  return undefined;
}

export function getProfileData() {
  return getAuthData()?.profile;
}

export function getPermissionsData(): PermissionsData | undefined {
  return getAuthData()?.permissions;
}

export function getTokenData(): string | null {
  return sessionStorage.getItem("mytoken");
}


export const convertFileToBase64 = (file) =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onload = () => resolve(reader.result);
    reader.onerror = reject;

    reader.readAsDataURL(file.rawFile);
  });

import * as React from "react";
import { useTheme } from "@mui/material/styles";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Box from "@mui/material/Box";
import { map } from "lodash";

export interface TabPanelProps {
  children?: React.ReactNode;
  dir?: string;
  index: number;
  value: number;
}

export const TabPanel = (props: TabPanelProps) => {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
    </div>
  );
};

export function a11yProps(index: number) {
  return {
    id: `full-width-tab-${index}`,
    "aria-controls": `full-width-tabpanel-${index}`,
  };
}

export interface TabData {
  label: string;
  content: React.ReactNode;
}

export const FullWidthTabs = ({ tabsData }: { tabsData: TabData[] }) => {
  const theme = useTheme();
  const [value, setValue] = React.useState(0);

  const handleChange = (_event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  return (
    <Box
      sx={{
        bgcolor: "background.paper",
        borderBottom: 1,
        borderColor: "divider",
        width: "100%",
      }}
    >
      <Tabs
        value={value}
        onChange={handleChange}
        aria-label=""
        variant="fullWidth"
        sx={{
          borderBottom: 1,
          borderColor: "divider",
        }}
      >
        {map(tabsData, (t, idx) => (
          <Tab
            key={idx}
            label={t.label}
            {...a11yProps(idx)}
            sx={{ fontWeight: "bold" }}
          />
        ))}
      </Tabs>

      {map(tabsData, (t, idx) => (
        <TabPanel key={idx} value={value} index={idx} dir={theme.direction}>
          {t.content}
        </TabPanel>
      ))}
    </Box>
  );
};

import { useGetList, SelectInput } from "react-admin";
import React from "react";
import { Grid } from "@mui/material";
import { EtiquetaTipos } from "../../lib/tipos";
import { isEmpty, toNumber } from "lodash";

const ComboEtiquetaInput = ({
  setValue,
  filterByPadreId,
  filterByTipo,
  sourceGroupName = undefined,
  isRequired = false,
  label,
  source,
}: {
  setValue: (v: number) => void;
  filterByPadreId?: number;
  filterByTipo?: string;
  isRequired?: boolean;
  sourceGroupName?: string;
  label: string;
  source: string;
}) => {
  const filter = React.useMemo(() => {
    let filterValues: { padre_id?: number; tipo?: string } = {};
    if (filterByPadreId) {
      filterValues.padre_id = filterByPadreId;
    }
    if (filterByTipo) {
      filterValues.tipo = filterByTipo;
    }

    return filterValues;
  }, [filterByPadreId, filterByTipo]);

  const { data, isLoading, error } = useGetList("etiquetas", {
    sort: {
      field: "nombre",
      order: "ASC",
    },
    filter,
    pagination: { page: 1, perPage: 10000 },
  });

  return (
    <SelectInput
      isRequired={isRequired}
      label={label}
      source={
        isEmpty(sourceGroupName) ? source : `${sourceGroupName}.${source}`
      }
      choices={data ? data : []}
      disabled={!!(!data || error)}
      optionText="nombre"
      optionValue="id"
      isLoading={isLoading}
      onChange={(e) => setValue(toNumber(e.target.value))}
      fullWidth
    />
  );
};

export const SelectTipologiaInput = ({
  sourceGroupName = undefined,
  source,
  isRequired = false,
}: {
  sourceGroupName?: string;
  source: string;
  isRequired?: boolean;
}) => {
  const [categoria, setCategoria] = React.useState<number | undefined>(
    undefined
  );
  const [subcategoria, setSubcategoria] = React.useState<number | undefined>(
    undefined
  );
  const [, setTipologia] = React.useState<number | undefined>(undefined);

  return (
    <Grid container rowSpacing={1} columnSpacing={{ xs: 2, sm: 2, md: 3 }}>
      <Grid item xs={4}>
        <ComboEtiquetaInput
          setValue={setCategoria}
          sourceGroupName={sourceGroupName}
          source="tipologia.padre.padre_id"
          filterByTipo={EtiquetaTipos.Categoria}
          label="Categoría"
          isRequired={isRequired}
        />
      </Grid>

      <Grid item xs={4}>
        <ComboEtiquetaInput
          setValue={setSubcategoria}
          sourceGroupName={sourceGroupName}
          source="tipologia.padre_id"
          filterByTipo={EtiquetaTipos.Subcategoria}
          filterByPadreId={categoria}
          label="Subcategoría"
          isRequired={isRequired}
        />
      </Grid>

      <Grid item xs={4}>
        <ComboEtiquetaInput
          setValue={setTipologia}
          sourceGroupName={sourceGroupName}
          source={source}
          filterByTipo={EtiquetaTipos.Tipologia}
          filterByPadreId={subcategoria}
          label="Tipología"
          isRequired={isRequired}
        />
      </Grid>
    </Grid>
  );
};

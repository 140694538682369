import Axios from 'axios';

// console.log(process.env.REACT_APP_PUBLIC_API_BACKEND_URL, process.env.REACT_APP_PUBLIC_BACKEND_URL)

export const axiosApi = Axios.create({
    baseURL: process.env.REACT_APP_PUBLIC_API_BACKEND_URL,
    headers: {
        'X-Requested-With': 'XMLHttpRequest',
    },
    withCredentials: true,
})

export const axiosBase = Axios.create({
    baseURL: process.env.REACT_APP_PUBLIC_BACKEND_URL,
    headers: {
        'X-Requested-With': 'XMLHttpRequest',
    },
    withCredentials: true,
})

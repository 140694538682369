import React from "react";
import { Card, CardContent, Typography, Skeleton, CircularProgress } from "@mui/material";
import {
  Title,
  FileInput,
  SimpleForm,
  usePermissions,
  FileField,
  SaveContextProvider,
  useDataProvider,
  useNotify,
  Button,
} from "react-admin";
import { BoxSeparator } from "../theme/utils";
import { isEditor, isLector } from "../lib/permissions";
import { replace } from "lodash";
import parse from 'html-react-parser';

const CustomUploadFilesComponent = (props: any) => {
  const {
    accept = undefined
  } = props;
  const dataProvider = useDataProvider();
  const [saving, setSaving] = React.useState(false);
  const notify = useNotify();
  const [state, setState] = React.useState('select-file');
  const [message, setMessage] = React.useState<string | undefined>(undefined);

  const save = data => {
    setSaving(true);
    dataProvider
      .create('fileupload', { data })
      .then((response) => {
        setState('success');
        setMessage(replace(response.data.data.message, "\\r\\n", '<br />'));
        // console.log(response);
      })
      .catch((err) => {
        setState('error');
        setMessage(err.toString());
        notify(err.toSring(), { type: 'error' });
      })
      .finally(() => {
        setSaving(false);
      })
  }


  const mutationMode = "pessimistic";


  return (
    <SaveContextProvider value={{ save, saving, mutationMode }}>
      {
        state === 'select-file' &&
        <SimpleForm>
          <FileInput source="fichero" multiple={false} accept={accept}>
            <FileField source="title" />
          </FileInput>
        </SimpleForm>
      }
      {
        saving && <CircularProgress />
      }
      {
        state === 'success' && <Card><CardContent>{message ? parse(message) : 'No hay mensajes!'}</CardContent></Card>
      }
      {
        state === 'error' && <Card><CardContent>{message ? parse(message) : 'No hay mensajes de error'}</CardContent></Card>
      }
      <div>&nbsp;</div>
      {state !== 'select-file' && <Button variant="contained" color="primary" onClick={() => window.location.reload()} label="Probar de nuevo" />}

    </SaveContextProvider>
  );
}



export const Dashboard = () => {
  const { isLoading, permissions } = usePermissions();

  if (isLoading) {
    return <Skeleton variant="rectangular" width="100%" height={200} />;
  } else {
    return (
      <>
        <Card>
          <Title title="Dashboard" />
          <CardContent>
            <Typography variant="h5" component="div">
              Zona de acciones comunes
            </Typography>
          </CardContent>
        </Card>
        <BoxSeparator />
        {isEditor(permissions) && (
          <>
            <Card>
              <CardContent>
                <Typography variant="h6" component="div">
                  Importación de ficheros excel
                </Typography>
                <Typography
                  sx={{ fontSize: 14 }}
                  color="text.secondary"
                  gutterBottom
                >
                  El fichero excel debe de contener las columnas establecidas y no
                  otras.
                </Typography>
                <CustomUploadFilesComponent accept=".xls,.csv,.xlsx" />
                <br />
                <a href={`${process.env.REACT_APP_PUBLIC_BACKEND_URL}/plantilla-importacion.xlsx`} target="_blank" rel="noreferrer">Descargar plantilla de ejemplo</a>

                <br />
                <a href={`${process.env.REACT_APP_PUBLIC_BACKEND_URL}/MANUAL-NUEVOS-CONTACTOS.pdf`} target="_blank" rel="noreferrer">Descargar Manual de Nuevos contactos</a>

              </CardContent>
            </Card>
            <BoxSeparator />
          </>
        )}
        {isLector(permissions) && (
          <Card>
            <CardContent>
              <Typography variant="h6" component="div">
                Enlaces de interes
              </Typography>

              <br />
              <a href={`${process.env.REACT_APP_PUBLIC_BACKEND_URL}/MANUAL-GENERAL-USO.pdf`} target="_blank" rel="noreferrer">Descargar Manual de uso</a>

            </CardContent>
          </Card>
        )}
      </>
    );
  }
};

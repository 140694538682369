import { Grid } from "@mui/material";
import {
  TextInput,
  AutocompleteInput,
} from "react-admin";
import { TiposDeVia } from "../../lib/constantes";
import { GridColumnSeparator } from "../../theme/utils";
import { SelectPoblacionInput } from "./SelectPoblacionInput";

export const DireccionCreateEditInnerForm = ({
  sourceGroupName = "direccion",
  isEdit = false,
}: {
  sourceGroupName?: string;
  isEdit?: boolean;
}) => (
  <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
    <Grid item xs={2}>
      <AutocompleteInput
        source={`${sourceGroupName}.tipo_de_via`}
        label="Tipo de Via"
        choices={TiposDeVia}
      />
    </Grid>
    <GridColumnSeparator />
    <Grid item xs={6}>
      <TextInput source={`${sourceGroupName}.nombre`} label="Via" fullWidth />
    </Grid>
    <GridColumnSeparator />
    <Grid item xs={2}>
      <TextInput source={`${sourceGroupName}.numero`} label="Número" />
    </Grid>

    <Grid item xs={3}>
      <TextInput source={`${sourceGroupName}.piso`} label="Piso" />
    </Grid>
    <GridColumnSeparator />
    <Grid item xs={3}>
      <TextInput source={`${sourceGroupName}.escalera`} label="Escalera" />
    </Grid>
    <GridColumnSeparator />
    <Grid item xs={4}>
      <TextInput
        source={`${sourceGroupName}.codigo_postal`}
        label="Código Postal"
        fullWidth
      />
    </Grid>
    <Grid item xs={12}>
      <SelectPoblacionInput
        sourceGroupName={sourceGroupName}
        isRequired
        isEdit={isEdit}
      />
    </Grid>
  </Grid>
);

import {
  ReferenceInput,
  AutocompleteInput,
  useRecordContext,
} from "react-admin";
import { Tipologia, representacionEtiqueta } from "../../lib/tipos";

const OptionRenderer = () => {
  const record: Tipologia = useRecordContext();
  return <span>{representacionEtiqueta(record)}</span>;
};

const inputText = (choice: Tipologia) => representacionEtiqueta(choice);

const matchSuggestion = (filter: string, choice: Tipologia): boolean => {
  return (
    choice.nombre.toLowerCase().includes(filter.toLowerCase()) ||
    (choice.padre ? matchSuggestion(filter, choice.padre) : false)
  );
};

export const SelectEtiquetaInput = ({
  label = "Etiqueta",
  source,
  isRequired = false,
  filtrarTipo = undefined,
}: {
  label?: string;
  source: string;
  isRequired?: boolean;
  filtrarTipo?: string;
}) => (
  <ReferenceInput
    source={source}
    reference="etiquetas"
    filter={filtrarTipo ? { tipo: filtrarTipo } : {}}
    perPage={100000}
  >
    <AutocompleteInput
      optionText={<OptionRenderer />}
      inputText={inputText}
      matchSuggestion={matchSuggestion}
      label={label}
      TextFieldProps={{
        placeholder: "Comienza a escribir para buscar un valor",
      }}
      isRequired={isRequired}
      fullWidth
    />
  </ReferenceInput>
);

import React from "react";
import {
  List,
  TextField,
  TextInput,
  useCreate,
  useCreateSuggestionContext,
  Datagrid,
  EmailField,
  RichTextField,
  TopToolbar,
  FilterButton,
  CreateButton,
  ExportButton,
} from "react-admin";

import { Alert, Dialog, DialogContent, DialogTitle } from "@mui/material";
import { get } from "lodash";

import { OrganizacionCreateEditBaseForm } from "./OrganizacionCreateEditBaseForm";
import { WithPermissions } from "../../lib/permissions";
import { PermissionsData } from "../../api-provider";
import { ComunidadFilter, ProvinciaFilter } from "../Direcciones/DireccionesFilters";
import { EtiquetaFilter } from "../Etiquetas/EtiquetasFilters";
import { ShowDialog } from "@react-admin/ra-form-layout";
import { OrganizacionShowForm } from "./OrganizacionShowForm";


const exporter = () => {
  // Vacio se auto descarga el fichero
};

const CustomAction = ({ className, filters }: any) =>
  <WithPermissions>
    {({ isEditor }: PermissionsData) => (
      <TopToolbar className={className}>
        <FilterButton filters={filters} />
        {isEditor && <CreateButton />}
        {isEditor && <ExportButton maxResults={10000} exporter={exporter} meta={{ exporter: true }} />}
      </TopToolbar>)}
  </WithPermissions>
  ;

const organizacionesFilters = [
  <TextInput label="Buscar" source="q" alwaysOn />,
  <ComunidadFilter source="comunidad_id" />,
  <ProvinciaFilter source="provincia_id" />,
  <EtiquetaFilter label="Categoría" tipo="CATEGORIA" source="categoria_id" />,
  <EtiquetaFilter
    label="Subcategoría"
    tipo="SUBCATEGORIA"
    source="subcategoria_id"
  />,
  <EtiquetaFilter label="Tipología" tipo="TIPOLOGIA" source="tipologia_id" />,
];


export const ListaOrganizacion = () => (
  <WithPermissions>
    {({ isEditor }: PermissionsData) => (
      <>
        <List
          hasCreate={isEditor}
          exporter={false /* Desactivado hasta que implementemos el exportador de verdad isEditor ? undefined : false */}
          empty={false}
          filters={organizacionesFilters}
          actions={<CustomAction />}
          perPage={25}
        >
          <Datagrid
            rowClick={isEditor ? "edit" : "show"}
            size="small"
            optimized
            sx={{
              "& .RaDatagrid-rowCell": { padding: "0 5 0 5" },
            }}
            bulkActionButtons={isEditor ? undefined : false}
          >
            <TextField source="nombre" label="Nombre" />
            <TextField source="telefono" label="Teléfono" />
            <TextField source="otro_telefono" label="Otro teléfono" />
            <EmailField source="email" label="eMail del contacto" />

            <TextField source="categoria" label="Categoría" />
            <TextField source="subcategoria" label="Subcategoría" />
            <TextField source="tipologia" label="Tipología" />
            <TextField source="provincia" label="Provincia" />
            <TextField source="comunidad" label="Comunidad" />

            <RichTextField
              source="observaciones"
              label="Observaciones"
              sortable={false}
            />
          </Datagrid>
        </List>
        {/* <CreateDialog title="Crear nueva organización" fullWidth maxWidth="lg">
          <DialogContent>
            <OrganizacionCreateEditBaseForm />
          </DialogContent>
        </CreateDialog>
        <EditDialog title="Editar organización" fullWidth maxWidth="lg">
          <DialogContent>
            <OrganizacionCreateEditBaseForm isEdit={true} />
          </DialogContent>
        </EditDialog> */}
        <ShowDialog title="Datos organización" fullWidth maxWidth="lg" queryOptions={{ meta: { 'refresh': true } }}>
          <DialogContent>
            <OrganizacionShowForm />
          </DialogContent>
        </ShowDialog> 
      </>
    )}
  </WithPermissions>
);

export const CrearOrganizacion = () => {
  const { filter, onCancel, onCreate } = useCreateSuggestionContext();
  const [create, { error, isError }] = useCreate();
  const [, setValue] = React.useState(filter || "");

  const handleSubmit = (data: any) => {
    create(
      "organizaciones",
      { data },
      {
        onSuccess: (data: any) => {
          setValue("");
          onCreate(data.data);
        },
      }
    );
  };

  return (
    <Dialog open onClose={onCancel} fullWidth maxWidth="md">
      <DialogTitle>Crear una nueva organización</DialogTitle>
      <DialogContent>
        <OrganizacionCreateEditBaseForm onSubmit={handleSubmit} />
        {isError && (
          <Alert severity="error">{`${get(error, "message")}`}</Alert>
        )}
      </DialogContent>
    </Dialog>
  );
};

import { map } from "lodash";
import { EtiquetaTipos } from "./tipos";


export const EtiquetasTiposArray = [
  { id: EtiquetaTipos.Categoria, name: "Categoría" },
  { id: EtiquetaTipos.Subcategoria, name: "Subcategoría" },
  { id: EtiquetaTipos.Tipologia, name: "Tipología" },
];


export const TiposDeVia = map(
  [
    "Alameda",
    "Calle",
    "Camino",
    "Carrer",
    "Carretera",
    "Glorieta",
    "Kalea",
    "Pasaje",
    "Paseo",
    "Plaça",
    "Plaza",
    "Rambla",
    "Ronda",
    "Rúa",
    "Sector",
    "Travesía",
    "Urbanización",
    "Avenida",
    "Avinguda",
    "Barrio",
    "Calleja",
    "Camí",
    "Campo",
    "Carrera",
    "Cuesta",
    "Edificio",
    "Enparantza",
    "Estrada",
    "Jardines",
    "Jardins",
    "Parque",
    "Passeig",
    "Praza",
    "Plazuela",
    "Placeta",
    "Poblado",
    "Via",
    "Travessera",
    "Passatge",
    "Bulevar",
    "Polígono",
    "Otros",
  ],
  (v) => ({ id: v, name: v })
);

import axios, { AxiosInstance } from "axios";
import { AuthProvider } from "react-admin";
import { getPermissionsData, getProfileData, processValidationErrorsMessages } from "./helpers";

export type LoginParams = {
  username: string;
  password: string;
};

export type PermissionsData = {
  isAdmin: boolean;
  isEditor: boolean;
}

export type AuthData = {
  permissions: PermissionsData;
  profile: {
    id: number;
    fullName: string;
  };
  token: string;
};



export class AxiosAuthProvider {
  axiosClient: AxiosInstance;
  redirectTo: string | undefined = undefined;

  constructor(httpClient: AxiosInstance, redirectTo?: string) {
    this.axiosClient = httpClient;
    this.redirectTo = redirectTo;
  }

  csrf() {
    return this.axiosClient.get("/sanctum/csrf-cookie");
  }

  async login({
    username,
    password,
  }: LoginParams): Promise<{ redirectTo?: string | boolean } | void | any> {
    await this.csrf();
    return this.axiosClient
      .post("/login", { email: username, password })
      .then(({ data: { data } }: { data: { data: AuthData }}) => {
        // console.log("login response: ", data);
        sessionStorage.setItem("mytoken", data.token);
        sessionStorage.setItem("auth", JSON.stringify(data));
        // Reurn redirect or whatever
        return { redirectTo: this.redirectTo || false };
      })
      .catch(processValidationErrorsMessages);
  }

  checkError(error: any) {
    if (axios.isAxiosError(error)) {
      sessionStorage.removeItem("auth");
      return Promise.reject({ message: "No autorizado" });
    }

    // other error code (404, 500, etc): no need to log out
    return Promise.resolve();
  }

  checkAuth(_params: any) {
    const auth = sessionStorage.getItem("auth");
    return typeof auth === "string" && null !== JSON.parse(auth)
      ? Promise.resolve()
      : Promise.reject();
  }

  logout() {
    const clearSession = () => {
      sessionStorage.removeItem("auth");
      sessionStorage.removeItem("mytoken");
      return Promise.resolve();
    };
    return this.axiosClient.post(`/logout`).then(clearSession, clearSession);
  }
  getIdentity() {
    const i = getProfileData();
    return i ? Promise.resolve(i) : Promise.reject();
  }

  handleCallback() {
    return Promise.reject("Only for 3rd party auth, not implemented!");
  }

  // authorization
  getPermissions() : Promise<PermissionsData> {
    const p = getPermissionsData();
    return p ? Promise.resolve(p) : Promise.reject();
  }

  asRaProvider(): AuthProvider {
    return {
      login: (params: LoginParams) => this.login(params),
      checkError: (error: any) => this.checkError(error),
      checkAuth: (params: any) => this.checkAuth(params),
      logout: () => this.logout(),
      getIdentity: () => this.getIdentity(),
      handleCallback: () => this.handleCallback(),
      // authorization
      getPermissions: () => this.getPermissions(),
    };
  }
}

import React from "react";
import { useGetList, useListFilterContext } from "ra-core";
import { AutocompleteArrayInput } from "react-admin";

export const CargoFilter = ({ source }: { source: string }) => {
  const { filterValues, setFilters } = useListFilterContext();

  const initialValues = React.useMemo(
    () => ({
      [source]: filterValues[source],
    }),
    [filterValues, source]
  );

  const { data, isLoading } = useGetList("cargos", {
    filter: initialValues,
    pagination: { page: 1, perPage: 10000 },
    sort: {
      field: 'nombre',
      order: 'ASC'
    }
  });

  const onSearchChange = (event) => {
    if (event.target) {
      setFilters({ ...filterValues, [source]: event.target.value }, null);
    } else {
      const { [source]: _, ...filters } = filterValues;
      setFilters(filters, null);
    }
  };

  return (
    <AutocompleteArrayInput
      sx={{
        width: '450px'
      }}
      debounce={500}
      label="Cargo"
      source={source}
      choices={data ? data : []}
      optionText="nombre"
      optionValue="id"
      isLoading={isLoading}
      onChange={onSearchChange}
      fullWidth
    />
    //   <SelectInput
    //     options={{
    //       label: "Cargo",
    //     }}
    //     source={source}
    //     choices={data ? data : []}
    //     optionText="nombre"
    //     optionValue="id"
    //     isLoading={isLoading}
    //     onChange={onSearchChange}
    //     fullWidth
    //   />
  );
};

import {
  List,
  TextField as TextFieldRa,
  Datagrid,
  TextInput,
} from "react-admin";
import { WithPermissions } from "../../lib/permissions";
import { EditableDatagrid, RowForm } from "@react-admin/ra-editable-datagrid";
import { PermissionsData } from "../../api-provider";

export const ListaPaises = () => (
  <WithPermissions>
    {({ isEditor }: PermissionsData) => (
      <List hasCreate={isEditor} exporter={isEditor ? undefined : false} empty={false}>
        {isEditor && (
          <EditableDatagrid
            mutationMode="undoable"
            editForm={<PaisForm />}
            createForm={<PaisForm />}
            size="small"
          >
            <TextFieldRa source="id" />
            <TextFieldRa source="pais" fullWidth />
          </EditableDatagrid>
        )}
        {!isEditor && (
          <Datagrid rowClick={false} size="small">
            <TextFieldRa source="id" />
            <TextFieldRa source="pais" fullWidth />
          </Datagrid>
        )}
      </List>
    )}
  </WithPermissions>
);

const PaisForm = () => (
  <RowForm>
    <TextFieldRa source="id" />
    <TextInput source="pais" isRequired fullWidth />
  </RowForm>
);
